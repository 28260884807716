<template>
  <div class="login">
    <div class="login-content">
      <header class="login-header">
        <div class="main-title">
          <svg class="ali-icon" aria-hidden="true">
            <use xlink:href="#icon-safe" />
          </svg>
          <h1 class="header-title">{{headerText}}</h1>
        </div>
        <!-- <div class="sub-title">道路运输企业隐患治理系统</div> -->
      </header>
      <div class="form-wrapper">
        <div class="input-wrapper">
          <Input v-model="formInput.name" type="text" size="large" prefix="md-contact" placeholder="请输入用户名"/>
        </div>
        <div class="input-wrapper">
          <Input v-model="formInput.password" type="password" size="large" prefix="md-lock" placeholder="请输入密码"/>
        </div>
        <div class="input-wrapper">
          <Button @click="login" :loading="loginLoading" type="primary" size="large" long>安全登录</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import {setUserInfoForStorage} from '@/assets/js/sessionStorage.js'
export default {
  data() {
    return {
      formInput: {
        name: '',
        password: ''
      },
      loginLoading: false
    }
  },
  computed: {
    logoSrc: function() {
      return WEB_CONFIG.logo
    },
    headerText: function() {
      return WEB_CONFIG.header ? WEB_CONFIG.header : '道路运输企业隐患治理系统'
    },
  },
  methods: {
    async login () {
      if(!this.formInput.name.trim() || !this.formInput.password.trim()) {
        this.$Message.warning('用户名或密码不能为空!')
        return
      }
      let _params = {
        userName: this.formInput.name,
        password: this.formInput.password
      }
      this.loginLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-api/home/login`,
          data: _params
        })
        this.loginLoading = false
        if (data.code === 200) {
          // session storage 存储
          setUserInfoForStorage(JSON.stringify(data.data))
          this.$Message.success('登录成功!')
          this.$router.push('/home')
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.loginLoading = false
        this.$Message.error('登录失败,请稍后重试!')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url('../../assets/imgs/background.svg');
  background-color: #F5F5F5;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  .login-content {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 350px;
    width: 450px;
    background-color: #F5F5F5;
    .login-header {
      position: relative;
      width: 100%;
      padding: 32px 0;
      .main-title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        .ali-icon {
          font-size: 40px;
          margin-right: 10px;
        }
        .header-title {
          margin-left: 10px;
          font-size: 30px;
        }
      }
      .sub-title {
        font-size: 14px;
        color: #808695;
        text-align: center;
      }
    }
    .form-wrapper {
      position: relative;
      width: 100%;
      padding: 0 20px;
      .input-wrapper {
        position: relative;
        width: 100%;
        padding: 10px;
      }
    }
  }
}
</style>